<template>
    <v-container>

        <div class="titulo text-center">
            <br>
            <label style=" font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 24px;
        letter-spacing: -0.02em; color: #FFFFFF;">SEGURO DE SALUD</label>
            <br>
            <label style=" font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 24px;
        letter-spacing: -0.02em; color: #FFFFFF;">MASCOTAS LIBRE ELECCIÓN</label>
        </div>

        <div class="text-center">

            <h4 style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 20px; line-height: 25px; letter-spacing: -0.01em;
                text-transform: uppercase; color: #FFB600; text-transform: uppercase;">¡GRACIAS POR CONFIAR <P style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 20px; line-height: 25px; letter-spacing: -0.01em;
                text-transform: uppercase; color: #FFB600; text-transform: uppercase;"> EN NOSOTROS!</P>
            </h4>

            <label class="text-center" style=" font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 25px;
                letter-spacing: -0.01em; color: #2D387C; text-transform: inherit;">{{ person.name }}, haz contratado <P
                    style=" font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 25px;
                letter-spacing: -0.01em; color: #2D387C;"> exitosamente tu seguro para mascotas.</P>
            </label>

        </div>

        <v-row>

            <v-col>

                <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto" height="250"
                    style="margin-top:-2%" max-width="400">
                    <v-card-text class=" font-weight-medium mt-12 text-center text-subtitle-1 color:primary">
                    </v-card-text>

                    <v-col class="text-center">
                        <label style=" font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px;
                                color: #232323; opacity: 0.75;">Recibirás tu póliza en un correo para que así la puedas
                            guardar, imprimir o consultar cuando quieras.</label>
                        <p></p>
                        <label style=" font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px;
                                color: #232323; opacity: 0.75;">La enviaremos a {{ person.email }}. Revisa tu bandeja
                            de
                            entrada, carpeta de SPAM y Promociones por si acaso.</label>
                    </v-col>
                </v-card>

                <br>

                <!-- <h3 class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 15px;
                    letter-spacing: -0.01em; color: #2D387C;">También ya puedes ver la poliza aquí:
                </h3>

                <br>

                <div class="text-center">
                    <v-btn style="padding: 12px 15px; gap: 10px; width: 345px; height: 50px; background: #2D387C; box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                        border-radius: 12px; text-transform:inherit; color: white;" elevation="0" @click="validate()">
                        <v-icon color="white" dark left>
                            mdi-file-multiple
                        </v-icon>
                        Ver la poliza
                    </v-btn>
                </div> -->

                <br>

                <h3 class="text-center" style=" font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 15px;
                    letter-spacing: -0.01em; color: #FFB600;">
                    ¿Quieres ver otros seguros?
                </h3>

                <br>

                <div class="text-center">
                    <v-btn style="padding: 12px 15px; gap: 10px; width: 345px; height: 50px; background: #FFB600; box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                        border-radius: 12px; text-transform:inherit; color: white;" elevation="0"
                        @click="returnPage()">
                        <v-icon color="white" dark left>
                            mdi-arrow-left
                        </v-icon>
                        Volver al home
                    </v-btn>
                </div>

                <br>

                <!-- <h3 class="text-center" style=" font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px;
                    color: #232323; opacity: 0.75;">
                    Si tienes problemas, no dudes en comunicarte <p style=" font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px;
                    color: #232323; opacity: 0.95;">con nosotros presionando aquí:</p>
                </h3>

                <div class="text-center">
                    <v-btn style="padding: 12px 15px; gap: 10px; width: 345px; height: 50px; background: #595959; box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                        border-radius: 12px; text-transform: uppercase; color: white;" elevation="0"
                        @click="validate()">
                        <v-icon color="white" dark left>
                            mdi-headset
                        </v-icon>
                        ATENCIÓN AL CLIENTE
                    </v-btn>
                </div> -->

            </v-col>

        </v-row>

        <br>

    </v-container>
</template>

<script>
import ShieldService from "../services/shield.service";
import swal from 'sweetalert';
import Titulo from "../assets/images/titulo.png"

export default {
    data() {
        return {
            success: null,
            id: null,
            insurance: null,
            load: false,
            person: {
                name: null,
                email: null,
            },
            Titulo,
            contactID: null,
            planData: localStorage.getItem("planId"),
            userData: {
                couponId: localStorage.getItem("couponId") == "null" ? null : localStorage.getItem("couponId"),
            },
        }
    },
    methods: {
        returnPage() {
            window.location.href = 'https://www.segurofacil.cl/';
        }
    },
    async mounted() {
        const query = this.$route.query;
        if (query) {
            if (query.success === "true") {

                var ageFilter = localStorage.getItem('ageQ')
                var unitFilter = localStorage.getItem('ageU')
                this.person.name = localStorage.getItem('name')
                this.person.email = localStorage.getItem('email')

                if (this.userData.couponId == null) {
                    await ShieldService.getPlans(null,
                        ageFilter, unitFilter).then((response) => {
                            response.forEach(element => {
                                if (element._id == this.planData) {
                                    this.planData = element
                                }
                            });
                        })
                } else {
                    await ShieldService.getPlans(this.userData.couponId,
                        ageFilter, unitFilter).then((response) => {
                            response.forEach(element => {
                                if (element._id == this.planData) {
                                    this.planData = element
                                }
                            });
                        })
                }

                this.success = true;
                this.id = query.id

                this.contactID = localStorage.getItem('contactID');

                if (this.contactID) {
                    const shieldRes = await ShieldService.updateContactActiveCampain(null, this.planData, null, this.contactID, 3);
                    console.log(shieldRes.data)
                }

                swal({
                    title: "¡Has contratado tu seguro con éxito! ",
                    text: "Revisa tu correo electrónico para obtener toda la información.",
                    icon: "success",
                    button: "Revisar detalle",
                });

            } else if (query.success === "false") {
                this.success = false;
            }
        }
    },

}
</script>

<style>
.v-alert {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 100%);
    margin: 0 auto;
}
</style>
